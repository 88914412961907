import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import L from "leaflet";
import "leaflet/dist/leaflet.css"; // Import Leaflet CSS
import "leaflet.fullscreen";
import "leaflet.fullscreen/Control.FullScreen.css";
import "./AiChatComponent.css";
import { auth } from "../firebase";

const {
  GoogleGenerativeAI,
  HarmCategory,
  HarmBlockThreshold,
} = require("@google/generative-ai");
const MODEL_NAME = "gemini-2.0-flash";

function AiChatComponent() {
  const [user, setUser] = useState(null);
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);
  const chatBoxRef = useRef(null);
  const mapRef = useRef(null);
  const mapInstanceRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        setUser(currentUser);
      } else {
        navigate("/login", { from: location.pathname });
      }
    });
    return () => unsubscribe();
  }, [navigate, location.pathname]);

  useEffect(() => {
    if (!mapInstanceRef.current && mapRef.current && user) {
      mapInstanceRef.current = L.map(mapRef.current, {
        zoomControl: true,
        fullscreenControl: true,
      }).setView([17.389017, 78.349541], 9);

      L.tileLayer("https://tile.openstreetmap.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      }).addTo(mapInstanceRef.current);

      setTimeout(() => {
        mapInstanceRef.current.invalidateSize();
      }, 100);
    }

    return () => {
      if (mapInstanceRef.current) {
        mapInstanceRef.current.remove();
        mapInstanceRef.current = null;
      }
    };
  }, [user]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
    if (mapInstanceRef.current) {
      mapInstanceRef.current.invalidateSize();
    }
  }, [messages]);

  const simulateTyping = async (char, delay) => {
    return new Promise((resolve) => setTimeout(() => resolve(char), delay));
  };

  function extractJsonAndText(responseText) {
    let json = [];
    let plainText = "";
    let braceCount = 0;
    let inJson = false;
    let jsonStartIndex = 0;
    let skipChars = 0; // To skip characters after closing JSON brace

    for (let i = 0; i < responseText.length; i++) {
      const char = responseText[i];
      if (char === "{") {
        if (braceCount === 0) {
          jsonStartIndex = i;
          inJson = true;
        }
        braceCount++;
      } else if (char === "}") {
        braceCount--;
        if (braceCount === 0 && inJson) {
          try {
            const jsonString = responseText.substring(jsonStartIndex, i + 1);
            json.push(JSON.parse(jsonString));
            inJson = false;
            skipChars = 7; // Skip " ```json" and the space after }
          } catch (error) {
            console.error("Error parsing JSON:", error);
          }
        }
      }

      if (!inJson && braceCount === 0) {
        if (skipChars > 0) {
          skipChars--;
        } else {
          plainText += char;
        }
      }
    }

    plainText = plainText.replace(/\s+/g, " ").trim(); // Clean up whitespace
    plainText = plainText.replace("```json", "");
    return { json, plainText };
  }

  const handleTyping = async (input) => {
    setIsTyping(true);
    const typingSpeed = 50; // ms per character

    for (let i = 0; i < input.length; i++) {
      const char = await simulateTyping(input[i], typingSpeed);
      setMessages((prevMessages) => {
        const newMessages = [...prevMessages];
        const lastMessageIndex = newMessages.length - 1;
        if (
          newMessages[lastMessageIndex] &&
          newMessages[lastMessageIndex].sender === "AI"
        ) {
          newMessages[lastMessageIndex] = {
            ...newMessages[lastMessageIndex],
            text: newMessages[lastMessageIndex].text + char,
          };
        } else {
          newMessages.push({ sender: "AI", text: char });
        }
        return newMessages;
      });
    }
    setIsTyping(false);
  };

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userInput.trim()) return;

    const newMessage = { sender: "User", text: userInput };
    setMessages((prevMessages) => [...prevMessages, newMessage]);
    setUserInput("");

    const responseText = await handleGeminiChat(userInput);
    setMessages((messages) => [...messages, { sender: "AI", text: "" }]);
    handleTyping(responseText);
  };

  
  async function handleGeminiChat(input) {
    const hash = "QUl6YVN5Q2Q1RktoUXlRem91RlRlWFlSdEVjb3BRQlJ3NFFoRmRz";
    const genAI = new GoogleGenerativeAI(decodeBase64(hash));
    const model = genAI.getGenerativeModel({ model: MODEL_NAME });

    const generationConfig = {
      temperature: 1,
      topP: 0.95,
      topK: 40,
      maxOutputTokens: 8192,
    };

    const safetySettings = [
      {
        category: HarmCategory.HARM_CATEGORY_HARASSMENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
      {
        category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
        threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
      },
    ];
    
    async function placeMarkers(locations) {
      if (!mapInstanceRef.current) {
        console.error("Map instance is not initialized");
        return;
      }

      // Clear existing markers
      if (mapInstanceRef.current.markers) {
        for (let marker of mapInstanceRef.current.markers) {
          marker.remove();
        }
      }
      mapInstanceRef.current.markers = [];

      let bounds = new L.LatLngBounds();

      for (const location of locations) {
        const url = `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(
          location.name + ", " + location.city
        )}`;
        const response = await fetch(url);
        const data = await response.json();

        if (data && data.length > 0) {
          const latLng = new L.LatLng(data[0].lat, data[0].lon);

          // Ensure the icon path is correct
          const icon = new L.Icon({
            iconUrl: "logo192.png", // Check if this path is correct
            iconSize: [25, 41],
            iconAnchor: [12, 41],
            popupAnchor: [1, -34],
          });

          const marker = L.marker(latLng, { icon })
            .addTo(mapInstanceRef.current)
            .bindPopup(`<b>${location.name}</b><br>${location.city}`);

          mapInstanceRef.current.markers.push(marker);
          bounds.extend(latLng);
        } else {
          console.error("No data found for location:", location.name);
        }
      }

      if (bounds.isValid()) {
        mapInstanceRef.current.fitBounds(bounds);
      } else {
        console.error("Invalid bounds detected, unable to fit map to markers.");
      }
    }

    const historyString = messages
      .map((msg) => `${msg.sender}: ${msg.text}`)
      .join(" | ");

    const chat = model.startChat({
        generationConfig,
        safetySettings,
        //history: historyString,
      });  

    const instruction = `Please respond in a concise and informative manner to the following question in less than 50 words. 
      Use the chat history below if provided. Try to focus on the latest question and answer that one as priority.
      In case the response includes locations, names of villas, or apartments, enclose them with *() and 
      ensure the locations include the city name by adding a comma followed by the city.
      Remember that having the location list along with information share is a top priority do not miss it.
      At the same time do not base your opinions on the example i gave for data. It is just to show you the format.
      Use them only if they are part of the answer you are giving me back
      Additionally, please provide a JSON object listing for all locations mentioned in the output separately.
      locations should always follow this format "locations": [
        {
          "name": "Financial District",
          "city": "Hyderabad"
        },
        {
          "name": "Gachibowli",
          "city": "Hyderabad"
        },
        {
          "name": "HITEC City",
          "city": "Hyderabad"
        }
        ,
        {
          "name": "Prestige Beverly Hills, Kokapet",
          "city": "Hyderabad"
        },
        {
          "name": "Paradise Circle, Secunderabad",
          "city": "Hyderabad"
        }`
        ;

    const customizedInput = `${instruction} chat history is as follows : ${historyString} Latest User Query is as follows : ${input}`;

    const result = await chat.sendMessage(customizedInput);
    const responseText = result.response.text();
    console.log(responseText);
    const keydata = extractJsonAndText(responseText);
    console.log(keydata);
    // Correctly access the locations array from the JSON response
    if (
      Array.isArray(keydata.json) &&
      keydata.json.length > 0 &&
      keydata.json[0].hasOwnProperty("locations")
    ) {
      const locations = keydata.json[0].locations;
      if (Array.isArray(locations)) {
        placeMarkers(locations);
      } else {
        console.error(
          "Expected 'locations' to be an array, received:",
          locations
        );
      }
    } else {
      console.error(
        "No locations found or 'locations' is not in the expected format:",
        keydata.json
      );
    }

    return keydata.plainText;
  }

  function decodeBase64(encodedString) {
    try {
      const decodedString = atob(encodedString);
      return decodedString;
    } catch (error) {
      console.error("Error decoding Base64 string:", error);
      return null;
    }
  }

  if (!user) return null;

  return (
    <div>
        <div>
    <div id="map" style={{ height: '40vh', width: '100%' }} ref={mapRef}></div>
    <div className="map-container">
      </div>
    <div >
    <div className="chat-box" ref={chatBoxRef}>
        {messages.map((msg, index) => (
          <p key={index} className={msg.sender === "User" ? "user-message" : "ai-message"}>
            <strong>{msg.sender}:</strong> {msg.text}
          </p>
        ))}
      </div>

    <form className="chat-input-form" onSubmit={handleSubmit} ref={chatBoxRef}>
        <input
          type="text"
          value={userInput}
          onChange={handleInputChange}
          placeholder="Enter a location name or ask a question"
          disabled={isTyping}
        />
        <button type="submit" disabled={isTyping}>Send</button>
      </form>
    </div>
    </div>
  </div>


  );
}

export default AiChatComponent;