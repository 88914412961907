import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import "./OffersPageComponent.css";

function OffersPageComponent() {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userToken, setUserToken] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const token = await user.getIdToken();
        setUserToken(token);
      } else {
        setUserToken(null);
      }
    });
    return () => unsubscribe();
  }, []);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSearch = async () => {
    if (!query) {
      setError("Please enter a query.");
      return;
    }
    setLoading(true);
    setError(null);

    if (!userToken) {
      setError("User is not authenticated. Please log in.");
      setLoading(false);
      return;
    }

    try {
      const response = await axios.post(
        'https://us-central1-focusgrounds-auth.cloudfunctions.net/callablefunction',
        { query },
        {
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${userToken}`
          }
        }
      );
      setResults(response.data);
    } catch (err) {
      setError(`Failed to fetch results: ${err.response?.data?.error || err.message}`);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const renderTable = () => {
    if (!results || results.length === 0) return null;

    return (
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Input</th>
            <th>Type</th>
          </tr>
        </thead>
        <tbody>
          {results.map((result) => (
            <tr key={result.id}>
              <td>{result.id}</td>
              <td>{result.input}</td>
              <td>{result.Type}</td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <div>
      <h2>Limited Time Offers Page</h2>
      <input
        type="text"
        placeholder="Enter your query"
        value={query}
        onChange={handleInputChange}
      />
      <button onClick={handleSearch} disabled={loading}>
        Search
      </button>
      {loading && <p>Loading...</p>}
      {error && <p style={{ color: "red" }}>{error}</p>}
      {results && renderTable()}
    </div>
  );
}

export default OffersPageComponent;
