import React, { useEffect, useRef } from 'react';
import { Outlet, Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import './MasterLayout.css';
import { Menu, X } from 'lucide-react';

const MasterLayout = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const toggleMenu = () => {
    menuRef.current.classList.toggle('active');
    buttonRef.current.classList.toggle('active');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        menuRef.current &&
        buttonRef.current &&
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        menuRef.current.classList.remove('active');
        buttonRef.current.classList.remove('active');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="master-layout">
      <header>
        <div className="header-left">
          <Link to="/">
            <img src="/logo192.png" alt="Logo" id="logo" />
          </Link>
          <h2>Focus Grounds</h2>
        </div>

        <nav className="nav-menu">
          <button className="menu-btn" onClick={toggleMenu} ref={buttonRef}>
            <Menu className="icon-open" />
            <X className="icon-close" />
          </button>

          <ul className="menu-links" ref={menuRef}>
            <li><Link to="/home">Home</Link></li>
            <li><Link to="/AiChat">Ai Chat</Link></li>
            <li><Link to="/offers">Deals</Link></li>
            <li><Link to="/demandsupply">Demand & Supply</Link></li>
            <li><Link to="/contactus">Contact Us</Link></li>
            {isAuthenticated ? (
              <li><button onClick={handleLogout}>Logout</button></li>
            ) : (
              <li><Link to="/login">Login</Link></li>
            )}
          </ul>
        </nav>
      </header>

      <main className="content-container">
        <Outlet />
      </main>

      <footer>&copy; {new Date().getFullYear()} Focus Grounds</footer>
    </div>
  );
};

export default MasterLayout;
