import React, { useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import styles from './DemandAndSupplyComponent.css';

/** Define the main categories and their subtypes */
const subTypeOptions = {
  Apartment: ['1 BHK', '2 BHK', '3 BHK', '4 BHK'],
  Villa: ['2 BHK', '3 BHK', '4 BHK', '5 BHK'],
  Plot: ['0-200 sq yards', '200-500 sq yards', '500+ sq yards']
};

/** Build a list of all property options for initializing the chart data */
const buildInitialData = () => {
  const data = [];
  Object.keys(subTypeOptions).forEach((category) => {
    subTypeOptions[category].forEach((subtype) => {
      data.push({ name: `${category} - ${subtype}`, demand: 0, supply: 0 });
    });
  });
  return data;
};

/** Custom legend that displays both Demand/Supply and category filters */
function CustomLegend(props) {
  const { payload, filterCategories, toggleFilter } = props;

  // `payload` is automatically passed by Recharts containing color & dataKey info
  // for the bars (demand, supply). We replicate the default legend items here
  // AND add our own checkboxes for category filtering.
  return (
    <div className={styles.customLegend}>
      {/* Top row: replicate the default Demand/Supply legend */}
      <div className={styles.legendRow}>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} className={styles.legendItem}>
            <div
              className={styles.legendColor}
              style={{ backgroundColor: entry.color }}
            />
            <span>{entry.value}</span>
          </div>
        ))}
      </div>

      {/* Second row: category filters */}
      <div className={styles.legendRow}>
        {Object.keys(subTypeOptions).map((category) => (
          <label key={category} className={styles.legendCheckboxLabel}>
            <input
              type="checkbox"
              checked={filterCategories.includes(category)}
              onChange={() => toggleFilter(category)}
            />
            {category}
          </label>
        ))}
      </div>
    </div>
  );
}

function DemandAndSupplyComponent() {
  // Initialize chart data with every property-subtype combination
  const [data, setData] = useState(buildInitialData());

  // State for Demand Panel
  const [demandCategory, setDemandCategory] = useState('Apartment');
  const [demandSubType, setDemandSubType] = useState(subTypeOptions['Apartment'][0]);

  // State for Supply Panel
  const [supplyCategory, setSupplyCategory] = useState('Apartment');
  const [supplySubType, setSupplySubType] = useState(subTypeOptions['Apartment'][0]);

  // State for filter checkboxes (default: all categories selected)
  const [filterCategories, setFilterCategories] = useState(['Apartment', 'Villa', 'Plot']);

  /** Handle adding demand */
  const addDemand = (event) => {
    event.preventDefault();
    const key = `${demandCategory} - ${demandSubType}`;
    const newData = data.map((item) => {
      if (item.name === key) {
        return { ...item, demand: item.demand + 1 };
      }
      return item;
    });
    setData(newData);
  };

  /** Handle adding supply */
  const addSupply = (event) => {
    event.preventDefault();
    const key = `${supplyCategory} - ${supplySubType}`;
    const newData = data.map((item) => {
      if (item.name === key) {
        return { ...item, supply: item.supply + 1 };
      }
      return item;
    });
    setData(newData);
  };

  /** Update demand sub-type when category changes */
  const handleDemandCategoryChange = (e) => {
    const newCategory = e.target.value;
    setDemandCategory(newCategory);
    setDemandSubType(subTypeOptions[newCategory][0]);
  };

  /** Update supply sub-type when category changes */
  const handleSupplyCategoryChange = (e) => {
    const newCategory = e.target.value;
    setSupplyCategory(newCategory);
    setSupplySubType(subTypeOptions[newCategory][0]);
  };

  /** Toggle checkbox selection for filter */
  const toggleFilter = (category) => {
    if (filterCategories.includes(category)) {
      setFilterCategories(filterCategories.filter((cat) => cat !== category));
    } else {
      setFilterCategories([...filterCategories, category]);
    }
  };

  /** Filter chart data based on selected filterCategories */
  // The item name format is "Category - Subtype"
  const filteredData = data.filter((item) => {
    const category = item.name.split(' - ')[0];
    return filterCategories.includes(category);
  });

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>Housing Demand & Supply Tracker</h2>

      <div className={styles.forms}>
        <div className={styles.formPanel}>
          <h3>Buyer (Demand)</h3>
          <form onSubmit={addDemand}>
            <label className={styles.label}>
              Property Category:
              <select
                value={demandCategory}
                onChange={handleDemandCategoryChange}
                className={styles.select}
              >
                {Object.keys(subTypeOptions).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </label>
            <label className={styles.label}>
              Sub-Type:
              <select
                value={demandSubType}
                onChange={(e) => setDemandSubType(e.target.value)}
                className={styles.select}
              >
                {subTypeOptions[demandCategory].map((sub) => (
                  <option key={sub} value={sub}>
                    {sub}
                  </option>
                ))}
              </select>
            </label>
            <button type="submit" className={styles.button}>Add Demand</button>
          </form>
        </div>

        <div className={styles.formPanel}>
          <h3>Seller (Supply)</h3>
          <form onSubmit={addSupply}>
            <label className={styles.label}>
              Property Category:
              <select
                value={supplyCategory}
                onChange={handleSupplyCategoryChange}
                className={styles.select}
              >
                {Object.keys(subTypeOptions).map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </select>
            </label>
            <label className={styles.label}>
              Sub-Type:
              <select
                value={supplySubType}
                onChange={(e) => setSupplySubType(e.target.value)}
                className={styles.select}
              >
                {subTypeOptions[supplyCategory].map((sub) => (
                  <option key={sub} value={sub}>
                    {sub}
                  </option>
                ))}
              </select>
            </label>
            <button type="submit" className={styles.button}>Add Supply</button>
          </form>
        </div>
      </div>

      <div className={styles.chartContainer}>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={filteredData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis allowDecimals={false} />
            <Tooltip />
            {/*
              We remove the default <Legend /> and replace it with our custom legend
              that includes both Demand/Supply items and the category checkboxes.
            */}
            <Legend/>
            <Bar dataKey="demand" fill="#8884d8" barSize={30} />
            <Bar dataKey="supply" fill="#82ca9d" barSize={30} />
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default DemandAndSupplyComponent;
