// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {getAuth,signOut} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import { getFunctions} from "firebase/functions";
//import { getFunctions, httpsCallable } from "firebase/functions";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyDMeRYErcV75lkFRd2bkGM5vFwnSUQ_KY4",
    authDomain: "focusgrounds-auth.firebaseapp.com",
    projectId: "focusgrounds-auth",
    storageBucket: "focusgrounds-auth.appspot.com",
    messagingSenderId: "575250600410",
    appId: "1:575250600410:web:61c8ea5af86b607108f0e3",
    measurementId: "G-HV131WQ5EC"
  };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth=getAuth();
export const db=getFirestore(app);
export const functions = getFunctions(app);
export { signOut };
export default app;