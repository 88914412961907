import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePageComponent.css';

function HomePageComponent() {
  const navigate = useNavigate();
  
  return (
    <div className="home-page">
     
        <h1>Find Your Dream Property with AI</h1>

      <div className="button-container">
        <button className="home-button" onClick={() => navigate('/AiChat')}>
          Chat with AI
        </button>
        <button className="home-button" onClick={() => navigate('/demandsupply')}>
          Market Insights
        </button>
        <button className="home-button" onClick={() => navigate('/offers')}>
          Special Deals
        </button>
      </div>
    </div>
  );
}

export default HomePageComponent;
