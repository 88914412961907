import React, { useState } from 'react';
import './SubscribeComponent.css';

const SubscribeComponent = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [plan, setPlan] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic
    console.log('Subscription Details:', { name, email, plan });
  };

  return (
    <div className="subscription-container">
      <h2 className="subscription-title">Subscribe to Our Service</h2>
      <form className="subscription-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name" className="form-label">Name</label>
          <input
            type="text"
            id="name"
            className="form-input"
            value={name}
            onChange={(e) => setName(e.target.value)}
            placeholder="Enter your name"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            id="email"
            className="form-input"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="plan" className="form-label">Select Plan</label>
          <select
            id="plan"
            className="form-select"
            value={plan}
            onChange={(e) => setPlan(e.target.value)}
            required
          >
            <option value="" disabled>Select a plan</option>
            <option value="basic">Basic Plan</option>
            <option value="premium">Premium Plan</option>
          </select>
        </div>
        <button type="submit" className="subscribe-button">Subscribe</button>
      </form>
    </div>
  );
};

export default SubscribeComponent;
