import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import AiChatComponent from './components/AiChatComponent';
import HomePageComponent from './pages/HomePageComponent';
import UploadListingsComponent from './components/UploadListingsComponent';
import ContactUsComponent from './components/ContactUsComponent';
import DemandAndSupplyComponent from './pages/DemandAndSupplyComponent';
import OffersPageComponent from './components/OffersPageComponent';
import DemoPageComponent from './components/DemoPageComponent';
import Login from './components/login';
import SignUp from './pages/RegisterPageComponent';
import Profile from './profile';
import { ToastContainer } from 'react-toastify';
import ProtectedRoute from './routes/ProtectedRoute';
import MasterLayout from './layouts/MasterLayout';
import { AuthProvider } from './contexts/AuthContext';
import Subscribe from './components/SubscribeComponent'

function App() {
  return (
    <AuthProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/" element={<MasterLayout />}>
              <Route index element={<HomePageComponent />} /> {/* Default Route */}
              <Route path="home" element={<HomePageComponent />} /> {/* Public Route */}
              <Route path="AiChat" element={<ProtectedRoute element={<AiChatComponent />} />} />
              <Route path="Subscribe" element={<ProtectedRoute element={<Subscribe />} />} />
              <Route path="upload" element={<ProtectedRoute element={<UploadListingsComponent />} />} />
              <Route path="offers" element={<ProtectedRoute element={<OffersPageComponent />} />} />
              <Route path="contactus" element={<ProtectedRoute element={<ContactUsComponent />} />} />
              <Route path="demandsupply" element={<ProtectedRoute element={<DemandAndSupplyComponent />} />} />
              <Route path="chat" element={<ProtectedRoute element={<AiChatComponent />} />} />
              <Route path="demo" element={<ProtectedRoute element={<DemoPageComponent />} />} />
              <Route path="profile" element={<ProtectedRoute element={<Profile />} />} />
              <Route path="login" element={<Login />} /> {/* Public Route */}
              <Route path="register" element={<SignUp />} /> {/* Public Route */}
            </Route>
            {/* Redirect any unknown routes to the default page */}
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
          <ToastContainer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
