import React, { useState } from 'react';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { auth, db } from '../firebase';
import { setDoc, doc } from 'firebase/firestore';
import './RegisterPageComponent.css';

function RegisterPageComponent() {
  const [formData, setFormData] = useState({
    fname: '',
    lname: '',
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});
  const [firebaseError, setFirebaseError] = useState('');

  const validate = () => {
    const newErrors = {};
    if (!formData.fname.trim()) newErrors.fname = 'First name is required.';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required.';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email address is invalid.';
    }
    if (!formData.password) {
      newErrors.password = 'Password is required.';
    } else if (formData.password.length < 6) {
      newErrors.password = 'Password must be at least 6 characters.';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    if (errors[name]) {
      setErrors((prevErrors) => ({ ...prevErrors, [name]: '' }));
    }
    if (firebaseError) setFirebaseError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validate()) return;

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      const user = userCredential.user;

      await setDoc(doc(db, 'Users', user.uid), {
        email: user.email,
        firstName: formData.fname,
        lastName: formData.lname,
      });

      alert('User Registered Successfully!');
      setFormData({
        fname: '',
        lname: '',
        email: '',
        password: '',
      });
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setFirebaseError('The email address is already registered. Please use a different email or log in.');
      } else if (error.code === 'auth/invalid-email') {
        setFirebaseError('The email address is invalid. Please enter a valid email.');
      } else if (error.code === 'auth/weak-password') {
        setFirebaseError('The password is too weak. Please enter a stronger password.');
      } else if (error.code === 'permission-denied') {
        setFirebaseError('You do not have permission to perform this action. Please check your Firestore rules.');
      } else {
        setFirebaseError('Registration failed. Please try again.');
      }
    }
  };

  return (
    <div className="register-page-container">
      <div className="register-page-form">
        <form onSubmit={handleSubmit}>
          <h3 className="text-center mb-4">Sign Up</h3>

          <div className="form-group mb-3">
            <label htmlFor="fname">First Name</label>
            <input
              type="text"
              name="fname"
              className={`form-control ${errors.fname ? 'is-invalid' : ''}`}
              placeholder="First name"
              value={formData.fname}
              onChange={handleChange}
            />
            {errors.fname && <div className="invalid-feedback">{errors.fname}</div>}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="lname">Last Name</label>
            <input
              type="text"
              name="lname"
              className="form-control"
              placeholder="Last name"
              value={formData.lname}
              onChange={handleChange}
            />
          </div>

          <div className="form-group mb-3">
            <label  htmlFor="email">Email Address</label>
            <input
              type="email"
              name="email"
              className={`form-control ${errors.email ? 'is-invalid' : ''}`}
              placeholder="Enter email"
              value={formData.email}
              onChange={handleChange}
            />
            {errors.email && <div className="invalid-feedback">{errors.email}</div>}
          </div>

          <div className="form-group mb-3">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              name="password"
              className={`form-control ${errors.password ? 'is-invalid' : ''}`}
              placeholder="Enter password"
              value={formData.password}
              onChange={handleChange}
            />
            {errors.password && <div className="invalid-feedback">{errors.password}</div>}
          </div>

          {firebaseError && <div className="alert alert-danger">{firebaseError}</div>}

          <div className="d-grid mb-3">
            <button type="submit" className="btn btn-primary">
              Sign Up
            </button>
          </div>
          <p className="text-center">
            Already registered? <a href="/login">Login</a>
          </p>
        </form>
      </div>
    </div>
  );
}

export default RegisterPageComponent;
